import React from "react"
import "../styles/index.css"
import { Helmet } from "react-helmet"

export default () => (
  <div>
    <Helmet>
     <meta charSet="utf-8" name="robots" content="noindex" name="theme-color" content="#000" />
      <title>🌴 gs / xyz</title>
      <link rel="canonical" href="https://gagansingh.xyz" />
      <script src="https://kit.fontawesome.com/6b889176de.js" crossorigin="anonymous"></script>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
    🌴🌴🌴
    <br/><br/><br/><br/>
    <a href="https://visuals.gagansingh.xyz">VISUALS ↗</a> is an ongoing archive of images I've collected since 2012. 
    <br /><br /><br /><br />
    <a href="javascript:history.back()" className="back"> {"<"} go back </a>
    <br /><br /><br /><br /><br />
    <div className="top-bar"><a href="/">☺</a></div>
  </div>
)